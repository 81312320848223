<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CCol col="6" class="styleHeader">{{ $t('message.showCustomerAccount') }}  </CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.customerName') }}</th>
                    <td>{{ customerAccount.customer.name }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.customerPhone') }}</th>
                    <td>{{ customerAccount.customer.phone }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.customerAddress') }}</th>
                    <td>{{ customerAccount.customer.address }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.typeOfCustomer') }}</th>
                   <td  v-if="customerAccount.customer.type_of_customer = 1">{{ $t('message.sellingPoint') }}</td>
                    <td  v-else-if="customerAccount.customer.type_of_customer = 2">{{ $t('message.distributor') }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.transferNumber') }}</th>
                    <td>{{ customerAccount.transfer_number }}</td>
                  </tr>

                </table>
            </div>
        </div>
        <CButton color="btn btn-primary" @click="goBack">{{ $t('message.back') }}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ShowCustomer',
  data () {
    return {
      customerAccount: [],
      moment: moment
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}customers-accounts/${this.$route.params.id}`)
      .then((response) => {
        this.customerAccount = response.data.data.customerAccount
      })
  },
  methods: {
    goBack () {
      this.citiesOpened ? this.$router.go(-1) : this.$router.push({ path: '/customers-accounts' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
</style>
